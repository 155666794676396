import React from 'react'

import Layout from '../components/layout'

const PrivacyPage = () => (
  <Layout>
    <section className="privacy-callout">
      <h2 className="privacy-callout__title">Privacy Policy</h2>
      <p className="privacy-callout__text">
        Protecting your privacy is incredibly important to us. This Privacy
        Policy explains our policy regarding the collection, use, disclosure
        and transfer of your information when you use our Services. By using
        our Service, you accept and agree to our information practices 
        described below.
      </p>      
    </section>
    <section>
      <h3>Collection and Use of Information</h3>
      <p>
        The Service does not collect any personally identifiable information ("PII"), which can be used
        to identify or contact you.

        We may collect anonymous information through third parties such as analytics and advertising tools.
        Some anonymous information that may be collected through these services includes:
        <ul>
          <li>
            Data and analytics about your use of our Service (e.g. in-game play progress, levels, games played).
          </li>
          <li>
            Your IP address and device identifiers such as your Advertising ID, Device ID numbers.
          </li>
          <li>
            Your device type and the operating system that you use.
          </li>
          <li>
            General geograph location based on your IP address.
          </li>
        </ul>
        See the <a href="#analytics">Analytics</a> section for more information about the type of anonymous
        information gathered.
      </p>

    </section>
    <section id="analytics">
      <h3>Analytics</h3>
      <p>
        The Service uses Firebase Analytics, a Google product, to collect 
        anonymous information about application usage. Any information 
        gathered in this way can not and will not identify you and is strictly 
        used to better understand and improve the Service experience. 
        You can view the <a href="https://firebase.google.com/support/privacy/">Firebase Privacy Policy</a> for 
        additional information.
        
        We reserve the right to change or add an analytics provider and will update 
        this document about the change.
    </p>
    </section>
    <section>
      <h3>Personal Information Security</h3>
      <p>
        We use appropriate measures to protect the security of your personal information both online and offline. 
        These measures vary based on the sensitivity of the information that we collect, process and store and 
        the current state of technology. Please note though that no service is completely secure. So, while 
        we strive to protect your personal information, we cannot guarantee that unauthorized access, hacking, 
        data loss or a data breach will never occur. 
      </p>
    </section>
    <section>
      <h3>Data Retention</h3>
      <p>
        The Service does not retain any information. However, if you feel that any personally identifiable information ("PII") 
        has been gathered by the service please contact support.

        If we discover that any PII data has been inadvertently gathered we will do our best to promptly notify
        the user and remove the information from our records.
      </p>
    </section>
    <section>
      <h3>Age Limit</h3>
      <p>
        We do not collect personal information. If we discover that we or a third party that
        shares data with us inadvertently gathered personal infomation from a minor under 13
        years of age we will take reasonable measures to promptly notify the user and delete
        such personal information from our records.
      </p>
    </section>
    <section>
      <h3>Privacy Policy Disputes</h3>
      <p>
        If you have a dispute with us relating to our privacy practices, please contact our customer support or 
        email us at <a href="mailto:support@galacticrex.com">rex.galacticrex<span style={{"font-family": "sans-serif"}}>@</span>gmail.com</a>. Most disputes can 
        be resolved that way.
      </p>
    </section>
    <section>
      <h3>Revisions</h3>
      <p>
        The current version of the Service privacy policy is found <a href="https://galacticrex.com/privacy">here</a>.
        We reserve the right to modify and update this policy. Changes will be
        listed below with the date and time the change was made.
      </p>
    </section>

  </Layout>
)

export default PrivacyPage